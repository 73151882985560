import Homepage from "./pages/Homepage";
import Stakepage from "./pages/Stakepage";
import Layout from "./components/Layout/Layout";
import audio from "./assets/ancient-one.mp3";
import Header from "./components/Header/Header";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { configureStore } from "./store/storeConfig";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Switch, Route, Redirect } from "react-router";

const Store = configureStore();

const App = () => { 
  const [playing, setPlaying] = useState(false);
  const player = new Audio(audio);

  useEffect(() => {
    playing ? player.play() : player.pause();
    return () => player.pause();
  }, [playing]);

  const togglePlay = () => setPlaying(s => !s);

  return (
    <Provider store={Store}>
      <Layout>
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          closeButton={false}
          rtl={false}
          pauseOnFocusLoss={true}
          draggable={true}
          pauseOnHover={true}
          limit={5}
          theme="dark"
        />
        <Header playPause={() => togglePlay()} isPlaying={playing} />
        <Switch>
          <Route path='/' exact>
            <Homepage playPause={() => togglePlay()} isPlaying={playing} />
          </Route>
          <Route path='/staking' exact>
            <Stakepage />
          </Route>
          <Route path='*'>
            <Redirect to='/' />
          </Route>
        </Switch>
      </Layout>
    </Provider>
  );
};

export default App;
