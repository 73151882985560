import styles from "./Faq.module.scss";
import MainWrapper from "../../UI/MainWrapper/MainWrapper";
import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";

const faqData = [
  {
    title: "What chain is this project on?",
    body: <p>The project currently exists on the Ethereum network.</p>,
  },
  {
    title: "What is the cost to mint critters?",
    body: <p>Critters are free for Sacred Sapling holders!</p>,
  },
  {
    title: "Will I be able to stake my sapling?",
    body: <p>Yes - for the lead-up to the game going live, you will be able to stake your Sacred Sapling to allow genesis holders to accrue $PSCI, which is the in-game utility token. This is the only way you'll be able to get it prior to the game going live. The intention is for staking to only be available as long as the game is not live - once it is live, users will be able to generate $PSCI and other elements by fruiting their saplings. We may introduce other staking opportunities in the future, post game launch.</p>,
  },
  {
    title: "What is The Evolving Forest game?",
    body: <>
      <p>The Evolving Forest is a blockchain game where you can purchase and care for your saplings. The utility token of the game - $PSCI or Pscillion - as well as other rare resources can be obtained through caring for your plants, bringing them to fruit or breeding them.</p>
      <p> Enter into the fortnightly plant beauty competition - Leaf League - and win grand prizes! Or enter your alien critters into the high stakes battle minigame - Critter Battler! Verse your friends and put your tiny companions up against formidable enemies in the hopes of receiving a reward of rare resources which are prime for trade or crafting.</p>
    </>
  },
  {
    title: "Does this product require me to connect an external wallet and what wallets does this product support?",
    body: <>
      <p>The Website: The Evolving Forest website uses both MetaMask and WalletConnect to allow users to connect their wallets to both view their NFTs and obtain their critters from the upcoming Critter Drop!!!!! (get hyped)</p>
      <p>The Game: The Evolving Forest Game requires you to connect a wallet that is provided through Sequence.xyz. This works by allowing users to sign into Sequence via their email, this will assign a unique Sequence Wallet to the provided email address.</p>
    </>,
  },
  {
    title: "What is included in the Early Access version of the game?",
    body: <>
      <p>Players can choose one of three unique guilds with their own designs and lore. From there they are given multiple plants that can be watered, have boosters applied to them, be fruited or bred and harvested for Pscillion and other resources that will assist in crafting.</p>
      <p>Players can also view their plants in up-close detail with the plant viewer and use the workshop to increase their crafting level by creating items that will assist in the care, growth and stats of their plants. If players are running low on resources they can play the Gene Splicer mini-game up to three times per day to obtain $PSCI and other useful resources.</p>
    </>,
  },
  {
    title: "What will be added in the future?",
    body: <p>As we progress towards a final release, we will be adding key updates such as the economy overhaul, Critter Battler, Leaf League plant competition, nursery and workshop redesigns and plant care contracts for free-to-play players.</p>,
  },
  {
    title: "What is the commission on OpenSea?",
    body: <p>The commission on OpenSea is 6.33%.</p>,
  }
];

const Faq = () => {
  const animationRef = useRef();
  const q = gsap.utils.selector(animationRef);

  useEffect(() => {
    gsap.fromTo(
      q("#roadmap-intro"),
      {
        y: "10%",
        opacity: 0,
      },
      {
        y: "0",
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: animationRef.current,
          start: "top bottom-=200",
          markers: false,
        }
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      q(".map-container"),
      {
        y: "10%",
        opacity: 0,
      },
      {
        y: "0",
        opacity: 1,
        duration: 1,
        stagger: 0.3,
        scrollTrigger: {
          trigger: q(".map-container"),
          start: "top bottom-=200",
          markers: false,
        }
      }
    );
  }, []);

  return (
    <section ref={animationRef} id="section-roadmap" className={styles.Roadmap}>
      <MainWrapper>
        <div id="roadmap-intro" className={styles.intro}>
          <h2>FAQ</h2>
        </div>
        <div className={styles.maps}>
          {faqData.map((data, index) => (
            <div
              className={`map-container ${styles.map} ${
                data.selected ? styles.selected : ""
              }`}
              key={index}
            >
              <div className={styles.title}>
                <span className={styles["title-text"]}>{data.title}</span>
              </div>
              <div className={styles.body}>
                {data.body}
              </div>
            </div>
          ))}
        </div>
      </MainWrapper>
    </section>
  );
};

export default Faq;
