const Mute = () => {
  return (
    <svg width="94" height="76" viewBox="0 0 94 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      
      <path d="M47.9998 0.579177L19.6988 21.6772H2.8008C1.1992 21.6772 0 22.978 0 24.478V50.978C0 52.5796 1.3008 53.7788 2.8008 53.7788H19.6988L47.9998 74.9778C49.8982 76.3762 52.4998 75.0793 52.4998 72.677V2.77898C52.4998 0.478177 49.8006 -0.822623 47.9998 0.579777V0.579177Z" fill="white"/>
    </svg>
  );
};

export default Mute;
