import styles from "./PurpleButton.module.scss";
import { Link } from "react-router-dom";

const PurpleButtonLink = (props) => {
  const { as } = props;

  if (as === 'Link') {
    return (
      <Link
        className={styles.PurpleButton}
        {...props}
        style={{ display: "block" }}
      />
    );
  }

  return (
    <a
      className={styles.PurpleButton}
      {...props}
      style={{ display: "block" }}
    />
  );
};

export default PurpleButtonLink;
