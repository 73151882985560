import { WALLET_DISCONNECTED } from "./auth";

export const STAKING_INFO_FETCHED = 'STAKING_INFO_FETCHED';

export const STAKING_STARTED = 'STAKING_STARTED';
export const STAKING_FINISHED = 'STAKING_FINISHED';

export const UNSTAKING_STARTED = 'UNSTAKING_STARTED';
export const UNSTAKING_FINISHED = 'UNSTAKING_FINISHED';

export const APPROVE_STAKING_STARTED = 'ALLOW_STAKING_STARTED';
export const APPROVE_STAKING_FINISHED = 'ALLOW_STAKING_FINISHED';

export const WITHDRAW_PSCI_STARTED = 'WITHDRAW_PSCI_STARTED';
export const WITHDRAW_PSCI_FINISHED = 'WITHDRAW_PSCI_FINISHED';

const defaultState = {
  tokens: []
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case STAKING_INFO_FETCHED:
      return {
        ...state,
        ...action.payload,
      };
    case WALLET_DISCONNECTED: 
      return {
        ...state, 
      }
    case STAKING_STARTED:
      return {
        ...state,
        staking: true,
      };

    case STAKING_FINISHED:
      return {
        ...state,
        staking: false,
      };
    case APPROVE_STAKING_STARTED:
      return {
        ...state,
        approvingStaking: true,
      };

    case APPROVE_STAKING_FINISHED:
      return {
        ...state,
        approvingStaking: false,
      };
    case UNSTAKING_STARTED:
      return {
        ...state,
        unstaking: true,
      };

    case UNSTAKING_FINISHED:
      return {
        ...state,
        unstaking: false,
      };
    case WITHDRAW_PSCI_STARTED:
      return {
        ...state,
        withdrawingPsci: true,
      };

    case WITHDRAW_PSCI_FINISHED:
      return {
        ...state,
        withdrawingPsci: false,
      };
    default:
      return state;
  }
}
