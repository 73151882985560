import styles from './PurpleText.module.scss'

const PurpleText = ({children}) => {
  return (
    <span className={styles.PurpleText}>
      {children}
    </span>
  )
}

export default PurpleText