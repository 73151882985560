import EvolvingForestLogo from "../../assets/EvolvingForestLogo";
import styles from "./FooterLogo.module.scss";

const FooterLogo = () => {
  return (
    <div className={styles.FooterLogo}>
      <div className={styles.logo}>
        <EvolvingForestLogo />
      </div>
      <div className={styles.text}>Evolving Forest</div>
    </div>
  );
};

export default FooterLogo;
