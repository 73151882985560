import styles from "./JoinTheCommunity.module.scss";
import MainWrapper from "../../UI/MainWrapper/MainWrapper";
import Forest from '../../../assets/pot_5_trunk_5_mat_1_leaves_4_flowers_5_color_4_prop_5 1.png'
import ArrowButton from "../../UI/ArrowButton/ArrowButton";
import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";

const JoinTheCommunity = () => {
  const animationRef = useRef();
  const q = gsap.utils.selector(animationRef);

  useEffect(() => {
    gsap.fromTo(
      q("#community-content"),
      {
        y: "10%",
        opacity: 0,
        rotationX: 45,
        transformOrigin: "0% 50% -50",
      },
      {
        y: "0",
        opacity: 1,
        rotationX: 0,
        duration: 1,
        scrollTrigger: {
          trigger: animationRef.current,
          start: "top bottom-=200",
          markers: false
        }
      }
    );
  }, []);

  return (
    <section ref={animationRef} className={styles.JoinTheCommunity}>
      <MainWrapper>
        <div id="community-content" className={styles.content}>
          <div className={styles.details}>
            <h2>Enter the Garden</h2>
            <p>
            Join fellow gardeners in the central commune! Stay informed on the whispers of the ancient one, prepare for each season to commence, and become one with the forest.
            </p>
            <a href="https://discord.gg/the-evolving-forest-888843021045620788" rel="noreferrer" target="_blank"><ArrowButton>Join our discord</ArrowButton></a>
          </div>
          <img src={Forest} alt='Evolving Forest' className={styles['forest-img']}/>
        </div>
      </MainWrapper>
    </section>
  );
};

export default JoinTheCommunity;
