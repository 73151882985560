import React from 'react';
import styles from './toast.module.scss';

export const TransactionInProgressToast = ({ hash }) => {
  return (
    <>
      <p>Transaction in progress</p>
      <a
        className={styles.toastLink}
        href={process.env.REACT_APP_ETHERSCAN_URL + '/tx/' + hash}
        target="_blank"
        rel="noreferrer"
      >
        Click here for details
      </a>
    </>
  );
};

export const TransactionErrorToast = ({ hash }) => {
  return (
    <>
      <p>An error has occured completing this transaction</p>
      <a
        className={styles.toastLink}
        href={process.env.REACT_APP_ETHERSCAN_URL + '/tx/' + hash}
        target="_blank"
        rel="noreferrer"
      >
        Click here for details
      </a>
    </>
  );
};

export const CustomErrorToast = ({ message }) => {
  return (
    <>
      <p>An error has occured sending this transaction</p>
      <span>{message}</span>
    </>
  );
};

export const WalletErrorToast = () => {
  return (
    <>
      <p>Wallet connection error detected</p>
      <a
        className={styles.toastLink}
        href="https://metamask.io/"
        target="_blank"
        rel="noreferrer"
      >
        Connect with MetaMask
      </a>
    </>
  );
};

export const SuccessToast = ({ hash }) => {
  return (
    <>
      <p>Transaction was successful!</p>
      <a
        className={styles.toastLink}
        href={process.env.REACT_APP_ETHERSCAN_URL + '/tx/' + hash}
        target="_blank"
        rel="noreferrer"
      >
        Click here for details
      </a>
    </>
  );
};
