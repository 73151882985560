const Play = () => {
  return (
    <svg width="96" height="76" viewBox="0 0 96 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M70.699 20C68.8006 18.1992 65.8982 18.3008 64.0974 20.1015C62.2966 21.9999 62.3982 24.9023 64.199 26.7031C67.3006 29.7031 68.9998 33.6015 68.9998 37.9021C68.9998 42.2029 67.3006 46.1013 64.199 49.1011C62.3006 50.9019 62.3006 53.9019 64.0974 55.7027C64.9958 56.7027 66.2966 57.1011 67.4958 57.1011C68.695 57.1011 69.7966 56.7027 70.7966 55.8003C75.695 51.1011 78.3982 44.6983 78.3982 37.8003C78.3005 31.1987 75.6013 24.8003 70.699 19.9993V20Z" fill="white"/>
      <path d="M81.3979 6.60195C79.4995 4.90275 76.4995 5.00035 74.7963 7.00039C73.0971 8.89879 73.1947 11.8988 75.1947 13.602C82.0931 19.8012 86.0927 28.704 86.0927 38C86.0927 47.3008 82.0927 56.199 75.1947 62.398C73.2963 64.0972 73.0931 67.0972 74.7963 68.9996C75.6947 69.9996 76.9955 70.6012 78.2963 70.6012C79.3979 70.6012 80.4955 70.2027 81.3979 69.402C90.2963 61.402 95.3979 50.004 95.3979 38.004C95.4994 26 90.3979 14.602 81.3979 6.60199V6.60195Z" fill="white"/>
      <path d="M48.4998 0.800978L20.1988 21.899H3.3008C1.6992 21.899 0.5 23.1998 0.5 24.6998V51.1998C0.5 52.8014 1.8008 54.0006 3.3008 54.0006H20.1988L48.4998 75.1996C50.3982 76.598 52.9998 75.3011 52.9998 72.8988V3.00078C52.9998 0.699979 50.3006 -0.600821 48.4998 0.801579V0.800978Z" fill="white"/>
    </svg>
  );
};

export default Play;
