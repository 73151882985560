import styles from "./ArrowButton.module.scss";
import ArrowIcon from "../../../assets/ArrowIcon";

const ArrowButton = ({ children }) => {
  return (
    <button className={styles.ArrowButton}>
      {children}
      <div className={styles.icon}>
        <ArrowIcon />
      </div>
      <div className={styles.icon}>
        <ArrowIcon />
      </div>
      <div className={styles.icon}>
        <ArrowIcon />
      </div>
    </button>
  );
};

export default ArrowButton;
