import style from './GuildInfo.module.scss'

import guildImg1 from '../../assets/Guild1.png'
import guildImg2 from '../../assets/Guild2.png'
import guildImg3 from '../../assets/Guild3.png'

import MainWrapper from "../UI/MainWrapper/MainWrapper";

const GuildInfo = () => {
  return (
    <MainWrapper>
      <div className={style.Heading}>
        <h2>The Guilds</h2>
      </div>
      <div className={style.Container}>

        <div className={style.Guild}> 
          <div className={style.ImgContainer}>
            <img src={guildImg1} alt='Vaccai'/>
          </div>
          <div className={style.ImgText}>
            <h2>Vaccai</h2>
            <p>The Vaccai are a scientific community who use their endless amounts of curiosity to test and push plants to their limits.</p>
          </div>
        </div>

        <div className={style.Guild}> 
          <div className={style.ImgContainer}>
            <img src={guildImg2} alt='The Crimson Guard'/>
          </div>
          <div className={style.ImgText}>
            <h2>The Crimson Guard</h2>
            <p>The Crimson Guard are an imperial faction focused on speed and productivity through industrial technologies.</p>
          </div>
        </div>

        <div className={style.Guild}> 
          <div className={style.ImgContainer}>
            <img src={guildImg3} alt='Dryari'/>
          </div>
          <div className={style.ImgText}>
            <h2>Dryari</h2>
            <p>The Dryari are native to the world of the Ancient One and have an innate understanding of plants and nature.</p>
          </div>
        </div>
      </div>
    </MainWrapper>
    
      

  )
}

export default GuildInfo