import { useState } from "react";
import styles from "./PlantItem.module.scss";
import Spinner from "./spinner.js"

const PlantItem = ({ details, selectable, onAdd, selected }) => {
  const [ loaded, setLoaded ] = useState(false);
  const { id, image } = details;

  const onClickHandler = () => {
    if (selectable) {
      onAdd(id);
    }
  };

  const onImgLoad = () => {
    setLoaded(true);
  }

  return (
    <div className={`${styles.PlantItem} ${selectable ? styles.selectable : ''}`} onClick={onClickHandler}>
      <div className={styles["image-container"]}>
        {selectable && (
          <div
            className={`${styles["check-box"]} ${selected ? styles.checked : ''}`}
          />
        )}
        <div className={styles['spinner-container']}>
          <Spinner />
        </div>
        <img src={image} alt={`Plant #${id}`} onLoad={onImgLoad} className={loaded ? styles['visible'] : ''}/>
      </div>
      <div className={styles.id}>Sacred Sapling #{id}</div>
    </div>
  );
};

export default PlantItem;
