import Web3 from 'web3';

export const fromWei = (num, decimals = 2) =>
  !num
    ? 0
    : Math.floor(Number(Web3.utils.fromWei(num)) * 10 ** decimals) /
      10 ** decimals;

export const formatAddress = (address) =>
  `${address.substr(0, 6)}...${address.substr(address.length - 4, 4)}`;

export const convertMS = (ms) => {
  let days, hours, minutes, seconds;
  seconds = Math.floor(ms / 1000);
  minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  days = Math.floor(hours / 24);
  hours = hours % 24;

  const s = n => n !== 1 ? 's' : '';

  return `${days ? `${days} day${s(days)}`: ''}${hours ? ` ${hours} hour${s(hours)}`: ''}${minutes ? ` ${minutes} minute${s(minutes)}`: ''}${seconds ? ` ${seconds} second${s(seconds)}`: ''}`

  // return `${days}:${hours < 10 ? '0' : ''}${hours}:${
  //   minutes < 10 ? '0' : ''
  // }${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
