import EvolvingForestLogo from "../../assets/EvolvingForestLogo";
import styles from "./HeaderLogo.module.scss";

const HeaderLogo = () => {
  return (
    <div className={styles.HeaderLogo}>
      <div className={styles.logo}>
        <EvolvingForestLogo />
      </div>
      <div className={styles.text}>
        <span className={styles.full}>The Evolving Forest</span>
        <span className={styles.min}>EF</span>
      </div>
    </div>
  );
};

export default HeaderLogo;
