import styles from "./TransparentCounter.module.scss";
import MinusIcon from "../../assets/MinusIcon";
import PlusIcon from "../../assets/PlusIcon";

const TransparentCounter = ({ amount, onChange, max }) => {
  const decrementHandler = () => onChange(Math.max(0, amount - 1));
  const incrementHandler = () => onChange(Math.min(amount + 1, max));

  return (
    <div className={styles.TransparentCounter}>
      <button onClick={decrementHandler} className={styles.segment}>
        <MinusIcon />
      </button>
      <div className={styles.segment}>{amount}</div>
      <button onClick={incrementHandler} className={styles.segment}>
        <PlusIcon />
      </button>
    </div>
  );
};

export default TransparentCounter;
