export const getAddress = (store) => store.auth.session?.address;

export const getEthBalance = (store) => store.auth.session?.balance;

export const getProvider = (store) => store.auth.session?.provider;

export const getSession = (store) => store.auth.session;

export const getNftTokenContract = (store) => store.auth.contracts.nftToken;

export const getNftFunctionsContract = (store) => store.auth.contracts.nftFunctions;

export const getStakingPoolContract = (store) => store.auth.contracts.nftStakingPool;

export const getPsciTokenContract = (store) => store.auth.contracts.psciToken;

export const getWhitelistStatus = (store) => store.home.whitelisted;

export const getPrice = (store) => store.home.price;

export const getSaleStartTime = (store) => store.home.saleStartTime;

export const getWhitelistEntry = store => store.home.whiteListEntry;
