const EvolvingForestLogo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#7657E6"
        fillRule="evenodd"
        d="M4.75 15c0 .462.25.866.626 1.083a.75.75 0 01-.752 1.299A2.75 2.75 0 016 12.25 2.75 2.75 0 018.75 15v5a.75.75 0 01-1.5 0v-5a1.25 1.25 0 10-2.5 0zM19.25 10c0 .462-.25.866-.625 1.083a.75.75 0 10.75 1.299A2.749 2.749 0 0018 7.25 2.75 2.75 0 0015.25 10v10a.75.75 0 001.5 0V10a1.25 1.25 0 112.5 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#7657E6"
        fillRule="evenodd"
        d="M6.75 7c0 .978.625 1.813 1.5 2.122a.75.75 0 11-.5 1.414 3.751 3.751 0 115-3.536v13a.75.75 0 01-1.5 0V7a2.25 2.25 0 00-4.5 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default EvolvingForestLogo;
