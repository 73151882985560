import styles from './Button.module.scss'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles);

const Button = (props) => {

  const buttonClasses = cx({
    Button: true,
    primary: props.primary,
    secondary: props.secondary,
    small: props.small,
  })

  return (
    <button {...props} className={buttonClasses} >
      {props.children}
    </button>
  )
}

export default Button