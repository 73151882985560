import Web3 from 'web3';
import EvolvingForestNftFunctions from '../abis/EvolvingForestNftFunctions';
import EvolvingForestNftToken from '../abis/EvolvingForestNftToken';
import pscillionTokenAbi from '../abis/PscillionToken';
import stakingPoolAbi from '../abis/EvolvingForestStakingPool';
import AuthService from '../services/AuthService';
import StorageService from '../services/StorageService';
import { ETH_BALANCE_FETCHED, WALLET_CONNECTED, WALLET_DISCONNECTED } from '../store/auth';
import { getAddress, getProvider, getSession } from '../store/selectors';
import { fetchAllInfo } from './home';
import { toast } from 'react-toastify';
import { WalletErrorToast } from '../utills/toast';
import { CONTRACT_INFO_FETCHED } from '../store/home';
import { fetchStakingInfo } from './staking';

export const fetchEtherBalance = () => async (dispatch, getState) => {
  const address = getAddress(getState());
  if (!address) return;
  const provider = getProvider(getState());

  const balance = await provider.eth.getBalance(address);

  dispatch({
    type: ETH_BALANCE_FETCHED,
    payload: balance,
  });
};

export const connectWithMetaMask = (action) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_INFO_FETCHED,
      payload: {
        connectingWallet: true,
      },
    });

    StorageService.disableRefresh();
    const session = await AuthService.connect();

    StorageService.storeSession({
      chainId: session.chainId,
      address: session.address,
    });

    const contracts = setContracts(session.provider);

    dispatch({
      type: WALLET_CONNECTED,
      payload: { session, contracts },
    });
    
    if (action) {
      setTimeout(action, 1000);
    }

    setTimeout(() => {
      dispatch(fetchAllInfo());
      dispatch(fetchStakingInfo(0, true));
    }, 0)
  } catch (e) {
    toast.error(<WalletErrorToast />);
  } finally {
    StorageService.enableRefresh();
    dispatch({
      type: CONTRACT_INFO_FETCHED,
      payload: {
        connectingWallet: false,
      },
    });
  }
};

export const disconnectWallet = () => async (dispatch, getState) => {
  const session = getSession(getState());
  if (!session) return;
  if (session.web3Modal) {
    session.web3Modal.clearCachedProvider();
  }
  StorageService.clearSession();

  dispatch({
    type: WALLET_DISCONNECTED,
  });
}

export const connectLocally = () => async (dispatch) => {
  try {
    const storedSession = StorageService.getSession();
    if (storedSession) {
      dispatch(connectWithMetaMask());
    } else {
      const web3Provider = new Web3(process.env.REACT_APP_WEB3_PROVIDER_URL);
      const session = { provider: web3Provider };
      const contracts = setContracts(web3Provider);

      dispatch({
        type: WALLET_CONNECTED,
        payload: { session, contracts },
      });
      
      setTimeout(() => {
        dispatch(fetchAllInfo());
        dispatch(fetchStakingInfo());
      }, 0)
    }
  } catch (e) {
    toast.error(<WalletErrorToast />);
  }
};

export const setContracts = (web3) => {
  return {
    nftToken: new web3.eth.Contract(
      EvolvingForestNftToken,
      process.env.REACT_APP_EVOLVING_FOREST_NFT_TOKEN_ADDRESS
    ),
    nftFunctions: new web3.eth.Contract(
      EvolvingForestNftFunctions,
      process.env.REACT_APP_EVOLVING_FOREST_NFT_FUNCTIONS_ADDRESS
    ),
    nftStakingPool: new web3.eth.Contract(
      stakingPoolAbi,
      process.env.REACT_APP_EVOLVING_FOREST_STAKING_ADDRESS
    ),
    psciToken: new web3.eth.Contract(
      pscillionTokenAbi,
      process.env.REACT_APP_EVOLVING_FOREST_PSCI_ADDRESS
    ),
  };
};
