import styles from "./StakepageStyling.module.scss";
import Button from "../UI/Button/Button";
import PlantItem from "./PlantItem/PlantItem";
import stakingDummyData from "../../data/staking-dummydata";
import { useEffect, useState } from "react";
import PurpleText from "../UI/PurpleText/PurpleText";
import { connect } from "react-redux";
import { stakeNfts, approveStaking } from "../../actions/staking";

const UnstakedPlants = ({ tokens, stakedTokens, stakeNfts, approveStaking, isApproved, approvingStaking, staking }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [toStakeList, setToStakeList] = useState([]);
  const unstakedPlants = tokens || [];

  useEffect(() => {
    setIsSelecting(false);
    setToStakeList([]);
  }, [tokens]);

  const selectAllHandler = () => {
    setIsSelecting(true);
    setToStakeList(unstakedPlants.map((plant) => plant.id));
  };

  const cancelHandler = () => {
    setIsSelecting(false);
    setToStakeList([]);
  };

  const stakeHandler = () => {

    console.log("Staking...", toStakeList);
    stakeNfts(toStakeList);
  };

  const approveHandler = () => {
    console.log("Approving...", toStakeList);
    approveStaking();
  };

  const addToListHandler = (id) => {
    if (staking) return;
    const foundItem = toStakeList.find((itemId) => itemId === id);

    if (!foundItem) {
      setToStakeList((prevList) => [...prevList, id]);
    } else {
      setToStakeList(toStakeList.filter((item) => item !== foundItem));
    }
  };

  const n = stakedTokens.length + toStakeList.length;

  let bonus = 0;
  if (n === 2) bonus = 0.2;
  if (n === 3) bonus = 0.333;
  if (n > 3) bonus = 0.5;

  const newRate = Math.round(n * (1 + bonus) * 10) / 10;
  const bonusPercent = Math.floor(bonus * 100) + '%';

  return (
    <>
      <div className={styles.heading}>
        <h2>Your Unstaked Plants</h2>
      </div>
      {isSelecting && (
        <div className={styles["select-container"]}>
          <div className={styles.top}>
            <div className={styles.left}>
              <h4>Adding {toStakeList.length} plants</h4>
              <p>Stake your saplings to accrue Pscillion, the in-game utility token.</p>
            </div>
            <div className={styles.right}>
              <div className={styles.details}>
                <div className={styles.detail}>
                  <h5>Applicable Bonus</h5>
                  <div className={styles.text}>{bonusPercent}</div>
                </div>
                <div className={styles.detail}>
                  <h5>Future Earning Rate</h5>
                  <div className={styles.text}>
                    {newRate} <PurpleText>$PSCI / day</PurpleText>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.control}>
            {!staking &&
              <Button secondary onClick={cancelHandler}>
                Cancel
              </Button>
            }
            <div className={styles["btn-gap"]} />
            {isApproved ?
              <Button primary onClick={stakeHandler} style={{ minWidth: '205px'}}>
                {staking ? 'Staking...' : 'Stake Selected Plants'}
              </Button>
              :
              <Button primary onClick={approveHandler} style={{ minWidth: '205px'}}>
                {approvingStaking ? 'Approving...' : 'Approve Staking'}
              </Button>
            }
            
          </div>
        </div>
      )}
      {!isSelecting && (
        <div className={styles.buttons}>
          <Button secondary onClick={() => setIsSelecting(true)}>
            Select Individual
          </Button>
          <div className={styles["btn-gap"]} />
          <Button primary onClick={selectAllHandler}>
            Select All
          </Button>
        </div>
      )}
      <div className={styles.grid}>
        {unstakedPlants.map((plant) => (
          <PlantItem
            key={plant.id}
            details={plant}
            selectable={isSelecting}
            selected={!!toStakeList.find((itemId) => itemId === plant.id)}
            onAdd={addToListHandler}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.staking
});

const mapDispatchToProps = {
  stakeNfts,
  approveStaking,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnstakedPlants);