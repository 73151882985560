import {
  STAKING_INFO_FETCHED,
  STAKING_STARTED,
  STAKING_FINISHED,
  UNSTAKING_STARTED,
  UNSTAKING_FINISHED,
  APPROVE_STAKING_STARTED,
  APPROVE_STAKING_FINISHED,
  WITHDRAW_PSCI_STARTED,
  WITHDRAW_PSCI_FINISHED,
} from '../store/staking';

import { TRANSACTION_CANCELLED } from '../utills/errors';
import { toast } from 'react-toastify';
import {
  TransactionInProgressToast,
  TransactionErrorToast,
  SuccessToast,
  CustomErrorToast,
} from '../utills/toast';
import { connectWithMetaMask, fetchEtherBalance } from './auth';
import { getUserTokens } from '../api/graphApi';
import { getAddress, getNftTokenContract, getProvider, getPsciTokenContract, getStakingPoolContract } from '../store/selectors';
import { fromWei } from "../utills/formatting";

const MAX_TOKEN_ID = '10000';
const IPFS_URL = 'https://raini.mypinata.cloud/ipfs/QmcyrFG3obxaAM7tkzrw2AzdoGZfBohsris6jmYtT25Btj/'

const getIsApproved = async (address, state) => {
  const nftContract = getNftTokenContract(state);
  const stakingAddress = process.env.REACT_APP_EVOLVING_FOREST_STAKING_ADDRESS;

  const isApproved = await nftContract.methods.isApprovedForAll(address, stakingAddress).call();
  console.log('is approved fetched')
  return isApproved;
}

// const getStakedTokens = async (address, state) => {
//   const stakingContract = getStakingPoolContract(state);

//   let tokens = await stakingContract.methods.getStakedTokens(address, MAX_TOKEN_ID).call();

//   const tokenMetadata = await Promise.all(tokens.map(async id => {
//     let result = await fetch(IPFS_URL + id);
//     result = await result.json();
//     return result;
//   }))

//   tokens = tokens.map((id, index) => {
//     const meta = tokenMetadata[index];
//     const image = meta.image.replace('ipfs://', 'http://raini.mypinata.cloud/ipfs/');
//     return { id, image };
//   })

//   return tokens;
// }

const getPsciBalance = async (address, state) => {
  const stakingContract = getStakingPoolContract(state);
  const balance = await stakingContract.methods.balanceOf(address).call();
  console.log('psci fetched')
  return fromWei(balance, 4);
}

const getRewardVars = async (address, state) => {
  const stakingContract = getStakingPoolContract(state);
  const vars = await stakingContract.methods.accountRewardVars(address).call();
  return vars;
}

const getInfo = async (getState, blockNumber = 0, getTokens) => {
  const state = getState();
  const address = getAddress(state);
  if (!address) {
    return {
      tokens: [],
      isApproved: false,
    }
  }

  if (getTokens) {
    const [
      isApproved,
      tokens,
      //psciBalance, 
      rewardVars,
    ] = await Promise.all([
      getIsApproved(address, state),
      getUserTokens(address.toLowerCase(), blockNumber),
      //getPsciBalance(address, state),
      getRewardVars(address, state),
    ]);
  
    console.log('User Tokens', tokens);
    return {
      tokens: tokens.unstaked,
      stakedTokens: tokens.staked,
      isApproved,
      //psciBalance,
      rewardVars,
    };
  } else {
    const [
      isApproved,
      rewardVars,
      //psciBalance, 
    ] = await Promise.all([
      getIsApproved(address, state),
      getRewardVars(address, state),
      //getPsciBalance(address, state),
    ]);
  
    return {
      isApproved,
      rewardVars,
      //psciBalance,
    };
  }

  
}

export const fetchAllInfo = (blockNumber, getTokens, after) => (dispatch) => {
  dispatch(fetchEtherBalance());
  dispatch(fetchStakingInfo(blockNumber, getTokens, after));
};

export const fetchStakingInfo = (blockNumber, getTokens, after) => async (dispatch, getState) => {
  const MAX_ATTEMPTS = 4;
  let data;
  
  for (let i = 0; i < MAX_ATTEMPTS; i++) {
    data = await getInfo(getState, blockNumber, getTokens);
    if (!data.error) break;
  }
  if (data.error) {
    throw data.error;
  }
  dispatch({
    type: STAKING_INFO_FETCHED,
    payload: data,
  });
  if (after) after();
}


export const approveStaking = () => async (dispatch, getState) => {
  const state = getState();
  const address = getAddress(state);
  const stakingAddress = process.env.REACT_APP_EVOLVING_FOREST_STAKING_ADDRESS;
  try {
    const web3 = getProvider(state);
    const nftContract = getNftTokenContract(state);

    await checkAddressAndRun(dispatch, address, web3, async () => {

      dispatch({ type: APPROVE_STAKING_STARTED });
      

      await runContract(
        () => nftContract.methods.setApprovalForAll(stakingAddress, true).send({ from: address }),
        { onSuccess: (receipt) => new Promise(resolve => 
            dispatch(fetchAllInfo(0, false, () => {
              dispatch({ type: APPROVE_STAKING_FINISHED })
              resolve();
            }))
          )
        },
      );

      
    });
  } catch (e) {
    dispatch({ type: APPROVE_STAKING_FINISHED });
    console.log(e);
  }
}

export const withdrawPsci = () => async (dispatch, getState) => {
  const state = getState();
  const address = getAddress(state);
  const stakingContract = getStakingPoolContract(state);
  const balance = await stakingContract.methods.balanceOf(address).call();

  try {
    const web3 = getProvider(state);

    await checkAddressAndRun(dispatch, address, web3, async () => {

      dispatch({ type: WITHDRAW_PSCI_STARTED });
      

      await runContract(
        () => stakingContract.methods.withdrawReward(balance).send({ from: address }),
        { onSuccess: (receipt) => new Promise(resolve => 
            dispatch(fetchAllInfo(0, false, () => {
              dispatch({ type: WITHDRAW_PSCI_FINISHED })
              resolve();
            }))
          )
        },
      );
    });
  } catch (e) {
    dispatch({ type: WITHDRAW_PSCI_FINISHED });
    console.log(e);
  }
}



export const stakeNfts = (tokenIds) => async (dispatch, getState) => {
  const state = getState();
  const address = getAddress(state);
  try {
    const web3 = getProvider(state);
    const stakingContract = getStakingPoolContract(state);

    await checkAddressAndRun(dispatch, address, web3, async () => {
      if (!tokenIds || !tokenIds.length) {
        toast.error(<CustomErrorToast message={'No tokens selected'} />);
        return;
      }

      dispatch({ type: STAKING_STARTED });
      await runContract(
        () => stakingContract.methods.stake(tokenIds).send({ from: address }),
        { onSuccess: (receipt) => new Promise(resolve => 
            dispatch(fetchAllInfo(receipt.blockNumber, true, () => {
              dispatch({ type: STAKING_FINISHED })
              resolve();
            }))
          )
        },
      );
    });
  } catch (e) {
    dispatch({ type: STAKING_FINISHED });
    console.log(e);
  }
}

export const unstakeNfts = (tokenIds) => async (dispatch, getState) => {
  const state = getState();
  const address = getAddress(state);
  try {
    const web3 = getProvider(state);
    const stakingContract = getStakingPoolContract(state);

    await checkAddressAndRun(dispatch, address, web3, async () => {
      if (!tokenIds || !tokenIds.length) {
        toast.error(<CustomErrorToast message={'No tokens selected'} />);
        return;
      }

      dispatch({ type: UNSTAKING_STARTED });
    
      await runContract(
        () => stakingContract.methods.unstake(tokenIds).send({ from: address }),
        { onSuccess: (receipt) => new Promise(resolve => 
            dispatch(fetchAllInfo(receipt.blockNumber, true, () => {
              dispatch({ type: UNSTAKING_FINISHED })
              resolve();
            }))
          )
        },
      );

      //dispatch(fetchAllInfo());
    });
  } catch (e) {
    dispatch({ type: UNSTAKING_FINISHED });
    console.log(e);
  }
}

const checkAddressAndRun = async (dispatch, address, web3, action) => {
  const [web3Account] = await web3.eth.getAccounts();
  if (!address || address !== web3Account) {
    return dispatch(connectWithMetaMask(action));
  }

  await action();
};

const runContract = async (action, options) => {
  return new Promise(async (resolve, reject) => {
    let txHash;
    let toastId;
    try {
      await action()
        .on('transactionHash', (hash) => {
          txHash = hash;
          toastId = toast(<TransactionInProgressToast hash={hash} />, { autoClose: false });
          if (options?.onHash) {
            options.onHash(hash);
          }
        })
        .on('receipt', async (receipt) => {
          if (options?.onSuccess) {
            await options.onSuccess(receipt);
          }
          toast.dismiss(toastId);
          setTimeout(() => 
            toast.success(<SuccessToast hash={txHash} />),
          500);
          
          resolve(receipt);
        });
    } catch (err) {
      toast.dismiss(toastId);
      if (err.code === TRANSACTION_CANCELLED) {
        toast.error(
          <CustomErrorToast message={'Transaction canceled by user'} />
        );
      } else {
        if (txHash) {
          toast.error(<TransactionErrorToast hash={txHash} />);
        } else {
          <CustomErrorToast message={'An error has occured completing this transaction'} />
        }
      }
      reject(err);
    }
  });
};
