import styles from "./Roadmap.module.scss";
import MainWrapper from "../../UI/MainWrapper/MainWrapper";
import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";

const roadmapData = [
  {
    title: "Q2 2023",
    body: "New forces and tactics are emerging that allow the economic pursuit of $PSCI and Sacred Saplings to thrive. Becoming more sustainable over the years and aligning with the Ancient One’s vision is resulting in a stronger economy that will become the backbone of all she stands for and presides over!",
  },
  {
    title: "Q3 2023",
    body: "As a new quarter arrives, so do new opportunities for gardeners to showcase their efforts. Gardeners can vie for glory and bragging rights over all others with their accomplishments through grand pageantry or by sending miniature critter warriors into an outrageous battle to claim victory and $PSCI from their fellow competitors. The gates will also be opened for those to take care of others’ plants for a small commission, thus allowing all to earn $PSCI for adequately aiding the children of the Ancient One in their times of need or through their earliest stages of life.",
  },
  {
    title: "Q4 2023",
    body: "After months of rigorous construction, installation and expansion, each guild and their respective quarters will reveal new mechanisms and maneuverability for gardeners to tend to their sacred plants’ needs, battle their fearsome critters and craft precious tools. Gardeners will now be able to experience these interactive stations and the wondrous world of The Evolving Forest in a whole new way!",
  },
  {
    title: "Q1 2024",
    body: "Through the Ancient One’s compassion and wisdom, she will allow her chosen ones and saplings to reveal their true potential to those who have been so diligently caring of her children, as well as allowing new pilgrims to witness her magnificence.",
  },
];

const Roadmap = () => {
  const animationRef = useRef();
  const q = gsap.utils.selector(animationRef);

  useEffect(() => {
    gsap.fromTo(
      q("#roadmap-intro"),
      {
        y: "10%",
        opacity: 0,
      },
      {
        y: "0",
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: animationRef.current,
          start: "top bottom-=200",
          markers: false,
        }
      }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      q(".map-container"),
      {
        y: "10%",
        opacity: 0,
      },
      {
        y: "0",
        opacity: 1,
        duration: 1,
        stagger: 0.3,
        scrollTrigger: {
          trigger: q(".map-container"),
          start: "top bottom-=200",
          markers: false,
        }
      }
    );
  }, []);

  return (
    <section ref={animationRef} id="section-roadmap" className={styles.Roadmap}>
      <MainWrapper>
        <div id="roadmap-intro" className={styles.intro}>
          <h2>Roadmap</h2>
          <p>
            This planting and cultivation guide is broken into quarters. It lays
            out the sequence through which the forest will take shape, and what
            gardeners should expect on the path to enlightenment.
          </p>
        </div>
        <div className={styles.maps}>
          {roadmapData.map((data, index) => (
            <div
              className={`map-container ${styles.map} ${
                data.selected ? styles.selected : ""
              }`}
              key={index}
            >
              <div className={styles.title}>
                <span className={styles.index}>
                  {(index + 1).toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  })}
                </span>
                <span className={styles.vr} />
                <span className={styles["title-text"]}>{data.title}</span>
              </div>
              <div className={styles.body}>
                <p>{data.body}</p>
              </div>
            </div>
          ))}
        </div>
      </MainWrapper>
    </section>
  );
};

export default Roadmap;
