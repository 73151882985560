import styles from "./StakepageStyling.module.scss";
import Button from "../UI/Button/Button";
import PlantItem from "./PlantItem/PlantItem";
import stakingDummyData from "../../data/staking-dummydata";
import { useEffect, useRef, useState } from "react";
import PurpleText from "../UI/PurpleText/PurpleText";
import { connect } from "react-redux";
import { unstakeNfts } from "../../actions/staking";

const UnstakedPlants = ({ stakedTokens = [], unstakeNfts, unstaking }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [toStakeList, setToStakeList] = useState([]);

  useEffect(() => {
    setIsSelecting(false);
    setToStakeList([]);
  }, [stakedTokens]);

  const selectAllHandler = () => {
    setIsSelecting(true);
    setToStakeList(stakedTokens.map((plant) => plant.id));
  };

  const cancelHandler = () => {
    setIsSelecting(false);
    setToStakeList([]);
  };

  const stakeHandler = () => {
    console.log("Unstaking...", toStakeList);
    unstakeNfts(toStakeList);
  };

  const addToListHandler = (id) => {
    if (unstaking) return;
    const foundItem = toStakeList.find((itemId) => itemId === id);

    if (!foundItem) {
      setToStakeList((prevList) => [...prevList, id]);
    } else {
      setToStakeList(toStakeList.filter((item) => item !== foundItem));
    }
  };

  const n = stakedTokens.length - toStakeList.length;

  let bonus = 0;
  if (n === 2) bonus = 0.2;
  if (n === 3) bonus = 0.333;
  if (n > 3) bonus = 0.5;

  const newRate = Math.round(n * (1 + bonus) * 10) / 10;
  const bonusPercent = Math.floor(bonus * 100) + '%';

  return (
    <>
      <div className={styles.heading}>
        <h2>Your Staked Plants</h2>
      </div>
      {isSelecting && (
        <div className={styles["select-container"]}>
          <div className={styles.top}>
            <div className={styles.left}>
              <h4>Removing {toStakeList.length} plants</h4>
            </div>
            <div className={styles.right}>
              <div className={styles.details}>
                <div className={styles.detail}>
                  <h5>Applicable Bonus</h5>
                  <div className={styles.text}>{bonusPercent}</div>
                </div>
                <div className={styles.detail}>
                  <h5>Future Earning Rate</h5>
                  <div className={styles.text}>
                    {newRate} <PurpleText>$PSCI / day</PurpleText>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.control}>
            {!unstaking &&
              <Button secondary onClick={cancelHandler}>
                Cancel
              </Button>
            }
            <div className={styles["btn-gap"]} />
            <Button primary onClick={stakeHandler} style={{ minWidth: '222px'}}>
              {unstaking ? 'Unstaking...' : 'Unstake Selected Plants'}
            </Button>
          </div>
        </div>
      )}
      {!isSelecting && (
        <div className={styles.buttons}>
          <Button secondary onClick={() => setIsSelecting(true)}>
            Select Individual
          </Button>
          <div className={styles["btn-gap"]} />
          <Button primary onClick={selectAllHandler}>
            Select All
          </Button>
        </div>
      )}
      <div className={styles.grid}>
        {stakedTokens.map((plant) => (
          <PlantItem
            key={plant.id}
            details={plant}
            selectable={isSelecting}
            selected={!!toStakeList.find((itemId) => itemId === plant.id)}
            onAdd={addToListHandler}
          />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.staking
});

const mapDispatchToProps = {
  unstakeNfts,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnstakedPlants);
