import { WALLET_DISCONNECTED } from "./auth";

export const CONTRACT_INFO_FETCHED = 'CONTRACT_INFO_FETCHED';
export const BUY_STARTED = 'BUY_STARTED';
export const BUY_FINISHED = 'BUY_FINISHED';

const defaultState = {
  price: 0,
  maxMintsPerTx: 0,
  presalePrice: 0,
  saleStartTime: 0,
  tokenBalance: 0,
  numberMinted: 0,
  whitelisted: false,
  buying: false,
  whiteListEntry: null,
  remainingMints: 0,
  loaded: false,
  remainingWhitelistMints: 0,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CONTRACT_INFO_FETCHED:
      return {
        ...state,
        ...action.payload,
      };
    case WALLET_DISCONNECTED: 
      return {
        ...state,
        tokenBalance: 0,
        numberMinted: 0,
        whiteListEntry: null,
        remainingWhitelistMints: 0,
        whitelisted: false,  
      }
    case BUY_STARTED:
      return {
        ...state,
        buying: true,
      };

    case BUY_FINISHED:
      return {
        ...state,
        buying: false,
      };
    default:
      return state;
  }
}
