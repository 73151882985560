import { TRANSACTION_CANCELLED } from '../utills/errors';
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk-recursion-detect';
import { rootReducer } from './reducer';

export const configureStore = () => {
  const handleError = (err = {}) => {
    if (err.code === TRANSACTION_CANCELLED) {
      return console.log('Transaction cancelled');
    }
    console.log(err);
  };

  const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({
    onError: handleError,
  });
  const middleware = [errorHandlerMiddleware, thunkMiddleware];
  return createStore(rootReducer, applyMiddleware(...middleware));
};
