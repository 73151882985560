import { useEffect, useState, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { connect } from 'react-redux';
import styles from './TopSection.module.scss';
import PlantRender from '../../../assets/Plant_Render.mp4';
import { buy } from '../../../actions/home';
import TransparentCounter from '../../TransparentCounter/TransparentCounter';
import { fromWei, convertMS } from '../../../utills/formatting';
import PlantRenderImg from '../../../assets/Plant_Render 1.jpg';
import TransparentButton from '../../UI/TransparentButton/TransparentButton';
import PurpleButton from '../../UI/PurpleButton/PurpleButton';
import Mute from '../../../assets/Mute';
import PurpleButtonLink from '../../UI/PurpleButton/PurpleButtonLink';
import { Link } from 'react-router-dom';

const TopSection = (props) => {

  const calculateTimeDelay = startTime => {
    const now = new Date().getTime();
    if (!startTime) {
      return null;
    }

    const diff = startTime * 1000 - now;
    return Math.max(diff, 0);
  }

  gsap.registerPlugin(ScrollTrigger);
  const videoRef = useRef();
  const animationRef = useRef();
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [releaseTimeDelay, setReleaseTimeDelay] = useState(calculateTimeDelay(props.saleStartTime));


  const setTimeDelay = (startTime) => {
    setReleaseTimeDelay(calculateTimeDelay(startTime));
  };

  const maxMints = Math.floor(fromWei(props.ethBalance, 6) / fromWei(props.price, 6));
  const overLimit = amount > maxMints && props.address;
  let maxBuyAmount;
  if (releaseTimeDelay > 0) {
    maxBuyAmount = Math.min(props.maxMintsPerTx, props.remainingWhitelistMints);
  } else {
    maxBuyAmount = props.maxMintsPerTx;
  }

  useEffect(() => {
    const interval = setInterval(
      () => setTimeDelay(props.saleStartTime),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, [props.saleStartTime]);

  useEffect(() => {
    gsap.fromTo(
      animationRef.current.querySelector('video'),
      {
        y: 0,
      },
      {
        y: '15%',
        scrollTrigger: {
          trigger: animationRef.current,
          start: 'top top',
          end: 'bottom top',
          scrub: true,
          //markers: false,
        },
      }
    );
  }, []);

  const updatePriceHandler = (val) => {
    setAmount(val);
    const price = props.price;
    setPrice(Math.floor(val * fromWei(price, 6) * 10 ** 5) / 10 ** 5);
  };

  const canPlayThroughHandler = (e) => {
    videoRef.current.muted = true;
    videoRef.current.play();
  };

  const canPlayHandler = (e) => {
    videoRef.current.muted = true;
  };

  const saleStarted = releaseTimeDelay !== null && releaseTimeDelay <= 0;
  const canBuy = props.loaded && !(!saleStarted && props.remainingWhitelistMints === 0) && (props.whitelisted || saleStarted) ;
  
  return (
    <section ref={animationRef} className={styles.TopSection}>
      <video
        ref={videoRef}
        src={PlantRender}
        playsInline
        loop
        autoPlay
        defaultmuted="true"
        muted
        poster={PlantRenderImg}
        onCanPlay={canPlayHandler}
        onCanPlayThrough={canPlayThroughHandler}
      >
        
        <source src="PlantRender" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      
      {/* <div className={styles.mint}>
        <div>
          <div className={styles.center}>
            {!saleStarted && props.whitelisted && (props.remainingWhitelistMints === 0 ?
              <div className={styles.price} style={{ lineHeight: '140%' }}>
                You have minted up to your whitelist limit. <br/>You'll be able to mint more in the public sale!
              </div>
              :
              <div className={styles.price}>
                You are whitelisted and can mint {props.remainingWhitelistMints} {props.remainingWhitelistMints === 1 ? "sapling" : "saplings"}!
              </div>
            )}
            
            {canBuy && <>
              <TransparentCounter 
                amount={amount}
                max={maxBuyAmount}
                onChange={updatePriceHandler}
              />
            </>}
            
          </div>
          <div className={styles.price}>
            {canBuy && <>
              <div>{price} ETH</div>
              {overLimit &&
                <div>You have insufficient funds!</div>               
              }
            </>}
          </div>
          <div className={styles.center}>
          {!canBuy ? (props.loaded && releaseTimeDelay !== null && <>
              <h2 style={{ color: 'white', textAlign: 'center' }}>
                Public mint begins in:
              </h2>
              
              <div style={{ color: 'white', textAlign: 'center', height: '20px' }}>
                {!!releaseTimeDelay && convertMS(releaseTimeDelay)}
              </div>            
            </>) : <>
            <PurpleButton
              onClick={() => props.buy(amount)}
              disabled={amount === 0 || overLimit || !props.address || props.connecting}
              loading={props.buying}
            >
              Mint Now
            </PurpleButton>
          </>}
          </div>
          
          <div className={styles.price} style={{ marginTop: '30px', height: '5px'}}>
            {props.tokensRemaining ? `${props.totalSupply - props.tokensRemaining} / 9336 Seeds Sown` : ''}
          </div>
        </div>
      </div> */}
    <div className={styles.mint}>
    <div className={styles.center}>
      <PurpleButtonLink
        href='https://opensea.io/collection/evolvingforest'
        target='_blank'
      >
        View on OpenSea
      </PurpleButtonLink>
      <div style={{marginTop: '20px'}} />
      <PurpleButtonLink
        as={'Link'}
        to='/staking'
      >
        Staking
      </PurpleButtonLink>
    </div>
    </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  ...state.home,
  ...state.common,
  address: state.auth.session?.address,
  ethBalance: state.auth.session?.balance,
});

const mapDispatchToProps = { buy };

export default connect(mapStateToProps, mapDispatchToProps)(TopSection);
