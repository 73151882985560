const TwitterIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      fill="none"
      viewBox="0 0 30 24"
    >
      <path
        fill="currentColor"
        d="M29.396 3.013a11.878 11.878 0 01-3.403.932A5.944 5.944 0 0028.6.667a11.84 11.84 0 01-3.763 1.438 5.924 5.924 0 00-10.095 5.403A16.821 16.821 0 012.531 1.317a5.905 5.905 0 00-.802 2.98 5.924 5.924 0 002.635 4.93 5.905 5.905 0 01-2.683-.74v.073a5.929 5.929 0 004.752 5.81 5.965 5.965 0 01-2.677.102 5.928 5.928 0 005.535 4.115 11.893 11.893 0 01-8.77 2.454 16.76 16.76 0 009.08 2.663c10.899 0 16.858-9.028 16.858-16.858 0-.255-.007-.513-.018-.765a12.035 12.035 0 002.957-3.067l-.002-.001z"
      ></path>
    </svg>
  );
};

export default TwitterIcon;
