import styles from "./Header.module.scss";
import MainWrapper from "../UI/MainWrapper/MainWrapper";
import HeaderLogo from "./HeaderLogo";
import TransparentButton from "../UI/TransparentButton/TransparentButton";
import DiscordLogo from "../../assets/DiscordLogo";
import TwitterIcon from "../../assets/TwitterIcon";
import Mute from "../../assets/Mute";
import Play from "../../assets/Play";
import { connect } from "react-redux";
import { connectWithMetaMask, disconnectWallet } from "../../actions/auth";
import { fromWei, formatAddress } from "../../utills/formatting";
import HideOnHover from "../UI/TransparentButton/HideOnHover";
import ShowOnHover from "../UI/TransparentButton/ShowOnHover";
import { Route, Link } from "react-router-dom";
import { connectLocally } from "../../actions/auth";
import { useEffect } from "react";

const Header = ({ session, connecting, connectWithMetaMask, disconnectWallet, isPlaying, playPause, connectLocally }) => {
  useEffect(() => {
    connectLocally();
  }, [])
  return (
    <header className={styles.Header}>
      <MainWrapper>
        <div className={styles.content}>
          <div className={styles.left}>
            <Link to='/'>
              <HeaderLogo />
            </Link>
          </div>
          <div className={styles.right}>
            <a className={styles.icon} href="https://discord.gg/the-evolving-forest-888843021045620788" rel="noreferrer" target="_blank">
              <DiscordLogo />
            </a>
            <a className={styles.icon} href="https://twitter.com/evolvingforest" target="_blank">
              <TwitterIcon />
            </a>
              {session?.address ? <>
                <TransparentButton onClick={() => disconnectWallet()}>
                  <div style={{ color: 'white', flexDirection: 'column', display: 'flex', alignItems: 'flex-end' }}>
                    <HideOnHover>{formatAddress(session.address)}</HideOnHover>
                    <ShowOnHover>Disconnect</ShowOnHover>
                  </div>
                </TransparentButton>
                <span className={styles.balance}>{fromWei(session.balance, 3)} ETH</span>
                
              </> : (
                <TransparentButton onClick={() => connectWithMetaMask()}>{connecting ? 'Connecting...' : 'Connect Wallet'}</TransparentButton>
              )}
            <Route path='/' exact>
              <div className={`${styles.icon} ${styles.audio}`} onClick={playPause}>
              {isPlaying ? <Play /> : <Mute />}
              </div>
            </Route>
          </div>
        </div>
      </MainWrapper>
    </header>
  );
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  connecting: state.home?.connectingWallet,
});

const mapDispatchToProps = {
  connectWithMetaMask,
  disconnectWallet,
  connectLocally,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
