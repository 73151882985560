import React, { Suspense } from 'react';
import styles from "./Footer.module.scss";
import MainWrapper from "../UI/MainWrapper/MainWrapper";
import DiscordLogo from "../../assets/DiscordLogo";
import TelegramLogo from "../../assets/TelegramLogo";
import TwitterIcon from "../../assets/TwitterIcon";
import FooterLogo from "./FooterLogo";
import { useLocation } from 'react-router-dom';
const PdfLinks = React.lazy(() => import('./PdfLinks'));

const Footer = () => {
  const location = useLocation()
  let footerClass

  if(location.pathname === '/') {
    footerClass = styles.large
  }

  return (
    <section className={`${styles.Footer} ${footerClass}`}>
      <MainWrapper>
        <div className={styles.top}>
          <div className={styles.left}>
            <FooterLogo />
            <p>9,336 seeds, bearing sacred saplings.</p>
          </div>
          <div className={styles.right}>
            <a className={styles.icon} href="https://discord.gg/the-evolving-forest-888843021045620788" rel="noreferrer" target="_blank">
              <DiscordLogo />
            </a>
            <a className={styles.icon} href="https://twitter.com/evolvingforest" target="_blank">
              <TwitterIcon />
            </a>
          </div>
        </div>
        <div className={styles.bot}>
          <p className={styles.cr}>© 2023 The Evolving Forest</p>
          <p className={styles.links}>
            <Suspense fallback={<></>}>
              <PdfLinks/>
            </Suspense>
          </p>
        </div>
      </MainWrapper>
    </section>
  );
};

export default Footer;
