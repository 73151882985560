import styles from "./TeamCard.module.scss";
import LinkedLogo from "../../assets/LinkedInLogo";

const TeamCard = (props) => {
  const { name, linkedin, image } = props;

  return (
    <div className={styles.TeamCard}>
      <div className={styles.top}>
        <div className={styles["img-container"]}>
          <img src={image} alt={name} />
        </div>
      </div>
      <div className={styles.bottom}>
        <span>{name}</span>
      </div>
    </div>
  );
};

export default TeamCard;
