import React from "react"
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";
import styles from "./EvolvingForestCarousel.module.scss";

import ArrowIcon from "../../../assets/ArrowIcon";
import ForestImgOne from "../../../assets/1.jpeg";
import ForestImgTwo from "../../../assets/2.jpeg";
import ForestImgThree from "../../../assets/3.jpeg";
import ForestImgFour from "../../../assets/4.jpeg";
import ForestImgFive from "../../../assets/5.jpeg";
import ForestImgSix from "../../../assets/6.jpeg";
import ForestImgSeven from "../../../assets/7.jpeg";
import ForestImgEight from "../../../assets/8.jpeg";
import ForestImgNine from "../../../assets/9.jpeg";
import ForestImgTen from "../../../assets/10.jpg";
import ForestImgEleven from "../../../assets/11.jpeg";
import ForestImgTwelve from "../../../assets/12.jpg";
import ForestImgThirteen from "../../../assets/13.jpg";
import ForestImgFourteen from "../../../assets/14.jpg";
import ForestImgFifteen from "../../../assets/15.jpeg";
import ForestImgSixteen from "../../../assets/16.jpeg";
import ForestImgSeventeen from "../../../assets/17.jpeg";
import ForestImgEighteen from "../../../assets/18.jpeg";
import ForestImgNineteen from "../../../assets/19.jpeg";
import ForestImgTwenty from "../../../assets/20.jpeg";
import EvolvingForestImage from "./EvolvingForestImage";

const evolvingForestsData = [
  {
    image: ForestImgOne,
  },
  {
    image: ForestImgTwo,
  },
  {
    image: ForestImgThree,
  },
  {
    image: ForestImgFour,
  },
  {
    image: ForestImgFive,
  },
  {
    image: ForestImgSix,
  },
  {
    image: ForestImgSeven,
  },
  {
    image: ForestImgEight,
  },
  {
    image: ForestImgNine,
  },
  {
    image: ForestImgFourteen,
  },
  {
    image: ForestImgEleven,
  },
  {
    image: ForestImgTwelve,
  },
  {
    image: ForestImgThirteen,
  },
  {
    image: ForestImgTen,
  },
  {
    image: ForestImgFifteen,
  },
  {
    image: ForestImgSixteen,
  },
  {
    image: ForestImgSeventeen,
  },
  {
    image: ForestImgEighteen,
  },
  {
    image: ForestImgNineteen,
  },
  {
    image: ForestImgTwenty,
  },
];

const EvolvingForestCarousel = () => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const onSlider = React.useRef(null);
  const [currentSlide, setCurrentSlider] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 3,
    duration: 1000,
    centered: true,
    loop: true,
    spacing: 0,
    slideChanged(slide) {
      setCurrentSlider(slide.details().relativeSlide);
    },
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    onSlider.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    onSlider.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [onSlider])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 8000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <div ref={onSlider} className={styles.EvolvingForestCarousel}>
      <div className={styles.container}>
        <div ref={sliderRef} className={`${styles.slider} keen-slider`}>
          {evolvingForestsData.map((forest, index) => (
            <EvolvingForestImage 
              key={index}
              forest={forest} 
              index={index} 
              slider={slider} 
              currentSlide={currentSlide} 
              />
          ))}
        </div>
        <div className={styles.maxWidthContainer}>
          {slider && (
            <div className={styles["btn-container"]}>
              <div className={styles.center}>
                <button className={`${styles.btn} ${styles.left}`} onClick={() => slider.prev()}>
                  <ArrowIcon />
                </button>
                <button className={styles.btn} onClick={() => slider.next()}>
                  <ArrowIcon />
                </button>
              </div>
            </div>
          )}
          {slider && (
            <div className={styles.dots}>
              {[...Array(slider.details().size).keys()].map((index) => {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      slider.moveToSlideRelative(index);
                    }}
                    className={`${styles.dot} ${
                      currentSlide === index ? styles.active : ""
                    }`}
                  >
                    <div className={styles.inner}></div>
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );

};

export default EvolvingForestCarousel;
