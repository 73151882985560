
const css = String.raw;

const Spinner = () => {
  return <>
    <style type="text/css">
      {style}
    </style>
    <div className="spinner-container"><div className="spinner-sections">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  </>;
}

export default Spinner;

const style = css`
  @keyframes fade {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .spinner-sections div {
    left: 47px;
    top: 24px;
    position: absolute;
    animation: fade linear 1s infinite;
    background: #9f01e8;
    width: 6px;
    height: 12px;
    border-radius: 3px / 6px;
    transform-origin: 3px 26px;
  }.spinner-sections div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #9f01e8;
  }.spinner-sections div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #9f01e8;
  }
  .spinner-container {
    width: 61px;
    height: 61px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .spinner-sections {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.61);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .spinner-sections div { box-sizing: content-box; }
`;

