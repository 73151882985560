const gql = String.raw;

const URL = process.env.REACT_APP_NFT_SUBGRAPH_URL;
const IPFS_URL = process.env.REACT_APP_IPFS_URL + process.env.REACT_APP_IPFS_METADATA_HASH + '/';
const MAX_ATTEMPTS = 10;

const doQuery = async (query, variables = {}) => {
  let result = await fetch(URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })
  result = await result.json();
  if (result.errors) throw new Error(result.errors);
  return result.data;
}

const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

export const getUserTokens = async (address, blockNumber = 0) => {

  const fetchMetadata = async (token) => {
    let result = await fetch(IPFS_URL + token.id);
    result = await result.json();
    return result;
  }
  
  const processMetadata = (metadata) => 
    (token, index) => {
      const meta = metadata[index];
      const image = meta.image.replace('ipfs://', process.env.REACT_APP_IPFS_URL);
      return { ...token, image };
    }

  const query = gql`
    query getUserTokens($address: String!) {
      owner(id: $address) {
        tokens(first:1000) {
          id
        }
        stakedTokens(first:1000) {
          id
        }
      }
      _meta {
        block {
          number
        }
      }
    }
  `;

  let attempts = 0;
  let result;
  while(attempts < MAX_ATTEMPTS) {
    result = await doQuery(query, { address });
    console.log(result._meta.block.number, blockNumber)
    if (result._meta.block.number >= blockNumber || attempts >= MAX_ATTEMPTS) {
      break
    }
    attempts++;
    await wait(500);
  }

  const owner = result.owner || { tokens: [], stakedTokens: [] };
  let tokens = owner.tokens;
  let stakedTokens = owner.stakedTokens;
  
  const [
    tokenMetadata,
    stakedTokenMetadata
  ] = await Promise.all([
    Promise.all(tokens.map(fetchMetadata)),
    Promise.all(stakedTokens.map(fetchMetadata)),
  ]);

  tokens = tokens.map(processMetadata(tokenMetadata));
  stakedTokens = stakedTokens.map(processMetadata(stakedTokenMetadata));
  console.log('tokens fetched')
  return { unstaked: tokens, staked: stakedTokens };
}