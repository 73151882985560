import Web3 from 'web3';
import StorageService from './StorageService';
import Web3Modal from 'web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";

if (window.ethereum) {
  window.ethereum.on('chainChanged', () => {
    StorageService.clearSession();
    window.location.reload();
  });

  window.ethereum.on('accountsChanged', () => {
    if (StorageService.isRefreshDisabled()) return;
    StorageService.clearSession();
    window.location.reload();
  });
}

export default class AuthService {
  static async connect() {

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID, // required
        },
      },
    };
    let web3;
    const web3Modal = new Web3Modal({
      network: process.env.REACT_APP_NETWORK_NAME,
      cacheProvider: true, // optional
      theme: 'dark',
      providerOptions, // required
    });
    try {
      const provider = await web3Modal.connect();
      web3 = new Web3(provider);
    } catch(error) {
      throw Error(error);
    }

    const accounts = await web3.eth.getAccounts();

    if (!accounts.length) {
      throw new Error('Cannot connect to wallet');
    }
    const [address] = accounts;
    const balance = await web3.eth.getBalance(address);
    return { provider: web3, balance, address, web3Modal };
  }
}
