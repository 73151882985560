import { useEffect, useRef } from "react";
import styles from "./HomePage.module.scss";
import TopSection from "../components/HomePageSections/TopSection/TopSection";
import EvolvingForestCarousel from "../components/HomePageSections/EvolvingForestCarousel/EvolvingForestCarousel";
import MainWrapper from "../components/UI/MainWrapper/MainWrapper";
import PurpleText from "../components/UI/PurpleText/PurpleText";
import Roadmap from "../components/HomePageSections/Roadmap/Roadmap";
import TeamCard from "../components/TeamCard/TeamCard";
import JoinTheCommunity from "../components/HomePageSections/JoinTheCommunity/JoinTheCommunity";
import Footer from "../components/Footer/Footer";
import ForestImgOne from "../assets/9.jpeg";
import ForestImgTwo from "../assets/5.jpeg";
import ForestImgThree from "../assets/3.jpeg";
import ForestImgFour from "../assets/1.jpeg";
import { gsap } from "gsap";
import { connect } from "react-redux";
import { connectLocally } from "../actions/auth";
import Faq from "../components/HomePageSections/Faq/Faq";
import GameflowCarousel from '../components/GameflowCarousel/GameflowCarousel'
import GuildInfo from "../components/GuildInfo/GuildInfo";

const teamData = [
  {
    name: "Master Gardener",
    linkedin: "https://www.google.com",
    image: ForestImgOne,
  },
  {
    name: "Ancient One's Chosen",
    linkedin: "https://www.google.com",
    image: ForestImgTwo,
  },
  {
    name: "Greenhouse Guardian",
    linkedin: "https://www.google.com",
    image: ForestImgThree,
  },
  {
    name: "Shaman of the Ancients",
    linkedin: "https://www.google.com",
    image: ForestImgFour,
  },
];

const Homepage = (props) => {
  const animationRef = useRef();
  const q = gsap.utils.selector(animationRef);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    gsap.from(animationRef.current.querySelector(".num_val"), {
      textContent: 0,
      duration: 3,
      ease: "power1.easeInOut",
      snap: { textContent: 1 },
      stagger: {
        each: 1.0,
        onUpdate: function() {
          this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
        },
      },
      scrollTrigger: {
        trigger: q("#section-one"),
        start: "top bottom-=200",
        markers: false
      }
    });
  }, []);

  useEffect(() => {
    gsap.fromTo(
      q("#container-one"),
      {
        y: "10%",
        opacity: 0,
      },
      {
        y: "0",
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: q("#section-one"),
          start: "top bottom-=200",
          markers: false
        }
      }
    );
  }, []);

  useEffect(() => {
    console.log(q("#section-two"));
    gsap.fromTo(
      q("#container-two"),
      {
        y: "10%",
        opacity: 0,
      },
      {
        y: "0",
        opacity: 1,
        duration: 1,
        scrollTrigger: {
          trigger: q("#section-two"),
          start: "top bottom-=200",
          markers: false
        }
      }
    );
  }, []);

  return (
    <div ref={animationRef}>
      <div className={styles["black-background"]}>
        <TopSection playPause={props.playPause} isPlaying={props.isPlaying} />
      </div >
      <section id="section-one">
        <MainWrapper>
          <div id="container-one" className={styles["split-text"]}>
            <div className={styles.left}>
              <div>
                <h2>
                  <PurpleText><span className="num_val">9336</span></PurpleText> Sacred<br/> Seedlings
                </h2>
              </div>
            </div>
            <div className={styles.right}>
              <p>
                The towering form of the Ancient One... <br/> 
                The alien glow of pscillion shards embedded in her soils... <br/> 
                What new life will unfurl from her blessing? Will you help shape her legacy?
              </p>
            </div>
          </div>
        </MainWrapper>
      </section>
      <EvolvingForestCarousel />
      <div className={styles["dark-background"]}>
        <Roadmap />
        <GuildInfo />
        <GameflowCarousel />

        <Faq />
        <section id="section-two">
          <MainWrapper>
            <div id="container-two" className={styles["split-text"]}>
              <div className={styles.left}>
                <div>
                  <h2>
                    <PurpleText>Prime</PurpleText>
                    <br />
                    Gardeners
                  </h2>
                </div>
              </div>
              <div className={styles.right}>
                <p>
                The four caretakers of the Ancient One are those first among this generation accepted by her calming embrace.
With combined experience in project management, smart contracts, public policy, and 3D art, they're well equipped to deliver the Ancient One's blessings to those that are worthy.
                </p>
              </div>
            </div>
          </MainWrapper>
        </section>
        <section className={styles["meet-our-team"]}>
          <MainWrapper>
            <div className={styles["team-wrapper"]}>
              {teamData.map((member, index) => (
                <TeamCard
                  key={index}
                  name={member.name}
                  linkedin={member.linkedin}
                  image={member.image}
                />
              ))}
            </div>
          </MainWrapper>
        </section>
        <JoinTheCommunity />
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  connectLocally,
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
