import { combineReducers } from 'redux';
import auth from './auth';
import home from './home';
import staking from './staking'

export const rootReducer = combineReducers({
  auth,
  home,
  staking,
});
