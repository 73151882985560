import styles from "./GameflowCarousel.module.scss";
import { useState } from "react";

const EvolvingForestImage = ( { forest, index, slider, currentSlide }) => {
  const [enableFullscreen, setEnableFullscreen] = useState(false);

  function fullScreenClickHandler() {
    setEnableFullscreen(!enableFullscreen)
  } 

  const shouldShow = (index) => {
    if (
      index === currentSlide ||
      index === currentSlide + 1 ||
      index === currentSlide - 1
    ) {
      return "";
    }

    if (currentSlide === slider.details().size - 1 && index === 0) {
      return "";
    }

    if (currentSlide === 0 && index === slider.details().size - 1) {
      return "";
    }

    return styles.hidden;
  };

  return (
  <div
    className={`${styles.slide} ${ index === currentSlide ? styles.active : "" } ${slider && shouldShow(index)} ${ enableFullscreen && styles.fullscreen} keen-slider__slide`}
    key={index} >
      <div className={`${styles.inner} ${ enableFullscreen && styles.fullscreen }`} onClick={fullScreenClickHandler}>
        <img src={forest.image} alt="forest" />
      </div>
  </div>
  );
};

export default EvolvingForestImage;
