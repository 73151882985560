import React from "react";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { useState } from "react";
import styles from "./GameflowCarousel.module.scss";

import ArrowIcon from "../../assets/ArrowIcon";
import CraftingImage from "../../assets/gameflowCarouselmages/Crafting.png";
import StakingImage from "../../assets/gameflowCarouselmages/Sapling Staking.png";
import GameflowImage from "../../assets/gameflowCarouselmages/Game Flow.png";
import PlantLifeCycle from '../../assets/gameflowCarouselmages/Plant Life Cycle.png'
import PlantStates from '../../assets/gameflowCarouselmages/Plant States.png'
import Resources from '../../assets/gameflowCarouselmages/Resources.png'
import PlantStateSerums from '../../assets/gameflowCarouselmages/Plant State Serums.png'
import Boosters from '../../assets/gameflowCarouselmages/Boosters.png'

import EvolvingForestImage from "./GameflowImage";

const evolvingForestsData = [
  {
    image: CraftingImage,
  },
  {
    image: StakingImage,
  },
  {
    image: GameflowImage,
  },
  {
    image: PlantLifeCycle,
  },
  {
    image: PlantStates,
  },
  {
    image: Resources,
  },
  {
    image: PlantStateSerums,
  },
  {
    image: Boosters,
  },
];

const GameflowCarousel = () => {
  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();
  const onSlider = React.useRef(null);
  const [currentSlide, setCurrentSlider] = useState(0);
  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 2,
    duration: 1000,
    centered: true,
    loop: true,
    spacing: 0,
    slideChanged(slide) {
      setCurrentSlider(slide.details().relativeSlide);
    },
  });

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true);
    });
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false);
    });
  }, [sliderRef]);

  React.useEffect(() => {
    onSlider.current.addEventListener("mouseover", () => {
      setPause(true);
    });
    onSlider.current.addEventListener("mouseout", () => {
      setPause(false);
    });
  }, [onSlider]);

  return (
    <div ref={onSlider} className={styles.GameflowCarousel}>
      <div className={styles.container}>
        <div ref={sliderRef} className={`${styles.slider} keen-slider`}>
          {evolvingForestsData.map((forest, index) => (
            <EvolvingForestImage
              key={index}
              forest={forest}
              index={index}
              slider={slider}
              currentSlide={currentSlide}
            />
          ))}
        </div>
        <div className={styles.maxWidthContainer}>
          {slider && (
            <div className={styles["btn-container"]}>
              <div className={styles.center}>
                <button
                  className={`${styles.btn} ${styles.left}`}
                  onClick={() => slider.prev()}
                >
                  <ArrowIcon />
                </button>
                <button className={styles.btn} onClick={() => slider.next()}>
                  <ArrowIcon />
                </button>
              </div>
            </div>
          )}
          {slider && (
            <div className={styles.dots}>
              {[...Array(slider.details().size).keys()].map((index) => {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      slider.moveToSlideRelative(index);
                    }}
                    className={`${styles.dot} ${
                      currentSlide === index ? styles.active : ""
                    }`}
                  >
                    <div className={styles.inner}></div>
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameflowCarousel;
