import styles from "./Details.module.scss";
import PurpleText from "../../UI/PurpleText/PurpleText";
import { connect } from "react-redux";
import Button from "../../UI/Button/Button";
import { withdrawPsci } from "../../../actions/staking";
import { useEffect, useState } from "react";
import Big from 'big.js';
import { fromWei } from "../../../utills/formatting";
//import { fromWei } from "../../utills/formatting";

const REWARD_RATE = Big(11574074074020);

const Details = ({ stakedTokens = [], withdrawPsci, withdrawingPsci, rewardVars = {} }) => {
  
  const n = stakedTokens.length;
  const [ time, setTime ] = useState(new Date().getTime())


  let bonus = 0;
  if (n === 2) bonus = 0.2;
  if (n === 3) bonus = 0.333;
  if (n > 3) bonus = 0.5;

  const baseRate = n;
  const actualRate = Math.round(n * (1 + bonus) * 10) / 10;
  const bonusPercent = Math.floor(bonus * 100) + '%';

  useEffect(() => {
    setInterval(() => {
      const ms = new Date().getTime();
      setTime(ms)
    }, 50)
  }, [])

  let totalPsci = 0;
  if (rewardVars.lastUpdated) {
    const elapsedTime =  (time / 1000) - rewardVars.lastUpdated;
    const reward = Big(REWARD_RATE).times(n).times(elapsedTime).times(1 + bonus);
    const totalRaw = Big(rewardVars.pointsBalance || 0).add(reward).round();
    totalPsci = fromWei(totalRaw.toFixed(), 4)
  }
  
  return (
    <div className={styles.Details}>
      <div className={styles.balance}>
        <div className={styles.line} />
        <div className={styles.content}>
          <h3>Your Earnings</h3>
          <div className={`${styles.text} ${styles.psci}`}>
            {totalPsci || '0'} <PurpleText>$PSCI</PurpleText>
          </div>
        </div>
        <div className={`${styles.line} ${styles.right}`} />
      </div>
      <div className={styles.buttonContainer}>
        {withdrawingPsci ? 
          <Button small primary>Withdrawing...</Button>
          :
          <Button small primary onClick={withdrawPsci}>Withdraw</Button>
        }
      </div>

      <div className={styles.earnings}>
        <div className={styles.item}>
          <h3>Base Earning Rate</h3>
          <div className={styles.text}>
            {baseRate} <PurpleText>$PSCI / day</PurpleText>
          </div>
        </div>
        <div className={styles.item}>
          <h3>Applicable Bonus</h3>
          <div className={styles.text}>
            {bonusPercent}
          </div>
        </div>
        <div className={styles.item}>
          <h3>Actual Earning Rate</h3>
          <div className={styles.text}>
            {actualRate} <PurpleText>$PSCI / day</PurpleText>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.staking
});

const mapDispatchToProps = {
  withdrawPsci,
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);

