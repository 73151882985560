export const WALLET_CONNECTED = 'WALLET_CONNECTED';
export const ETH_BALANCE_FETCHED = 'ETH_BALANCE_FETCHED';
export const WALLET_DISCONNECTED = 'WALLET_DISCONNECTED';

const defaultState = {
  session: null,
  contracts: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case WALLET_CONNECTED:
      return action.payload;
    case WALLET_DISCONNECTED: {
      return defaultState;
    }
    case ETH_BALANCE_FETCHED:
      return {
        ...state,
        session: { ...state.session, balance: action.payload },
      };

    default:
      return state;
  }
}
