export default class StorageService {
  static SesionKey = 'SESSION_KEY';
  static DisableRefreshKey = 'DISABLE_REFRESH_KEY';

  static disableRefresh() {
    localStorage.setItem(this.DisableRefreshKey, 'true');
  }

  static enableRefresh() {
    localStorage.removeItem(this.DisableRefreshKey);
  }

  static isRefreshDisabled() {
    const result = localStorage.getItem(this.DisableRefreshKey);
    return !!result;
  }

  static storeSession(session) {
    localStorage.setItem(this.SesionKey, JSON.stringify(session));
  }

  static getSession() {
    const result = localStorage.getItem(this.SesionKey);
    return JSON.parse(result);
  }

  static clearSession() {
    localStorage.removeItem(this.SesionKey);
  }
}
