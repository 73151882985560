import {
  CONTRACT_INFO_FETCHED,
  BUY_STARTED,
  BUY_FINISHED,
} from '../store/home';
import {
  getAddress,
  getProvider,
  getNftTokenContract,
  getNftFunctionsContract,
  getEthBalance,
  getPrice,
  getWhitelistEntry,
} from '../store/selectors';
import { connectWithMetaMask, fetchEtherBalance } from './auth';
import { TRANSACTION_CANCELLED } from '../utills/errors';
import { toast } from 'react-toastify';
import {
  TransactionInProgressToast,
  TransactionErrorToast,
  SuccessToast,
  CustomErrorToast,
} from '../utills/toast';
import whitelist from '../data/whitelist.json';

const getInfo = async getState => {
  const store = getState();
  const nftTokenContract = getNftTokenContract(store);
  const nftFunctionsContract = getNftFunctionsContract(store);
  const address = getAddress(store);

  let price, maxMintsPerTx, saleStartTime, totalSupply, nextTokenId, tokensRemaining;
  let tokenBalance = 0;
  let numberMinted = 0;
  let whiteListEntry = null;
  let remainingWhitelistMints = 0;
  
  try {
    [
      price,
      maxMintsPerTx,
      saleStartTime,
      totalSupply,
      nextTokenId,
    ] = await Promise.all([
      nftFunctionsContract.methods.price().call(),
      nftFunctionsContract.methods.maxMintsPerTx().call(),
      nftFunctionsContract.methods.saleStartTime().call(),
      nftTokenContract.methods.totalSupply().call(),
      nftTokenContract.methods.nextTokenId().call(),
    ]);
  
    tokensRemaining = totalSupply - nextTokenId + 1;
  
    if (address) {
      whiteListEntry = whitelist[address.toLowerCase()];
      [tokenBalance, numberMinted] = await Promise.all([
        nftTokenContract.methods.balanceOf(address).call(),
        nftTokenContract.methods.numberMintedByAddress(address).call(),
      ]);
      numberMinted = parseInt(numberMinted)
      if (whiteListEntry) {
        remainingWhitelistMints = whiteListEntry.maxMints - numberMinted;
      }
    }
  } catch (err) {
    return { error: err }
  }


  return {
    price,
    maxMintsPerTx,
    saleStartTime,
    tokenBalance,
    numberMinted,
    whiteListEntry,
    remainingWhitelistMints,
    whitelisted: !!whiteListEntry,
    totalSupply,
    tokensRemaining,
    loaded: true,
  }
}

export const fetchAllInfo = () => (dispatch) => {
  dispatch(fetchContractsInfo());
  dispatch(fetchEtherBalance());
};

export const fetchContractsInfo = () => async (dispatch, getState) => {
  const MAX_ATTEMPTS = 4;
  let data;
  
  for (let i = 0; i < MAX_ATTEMPTS; i++) {
    data = await getInfo(getState);
    if (!data.error) break;
  }
  if (data.error) {
    throw data.error;
  }
  dispatch({
    type: CONTRACT_INFO_FETCHED,
    payload: data,
  });
}

export const buy = (amount) => async (dispatch, getState) => {
  const state = getState();
  const address = getAddress(state);
  
  try {
    const web3 = getProvider(state);
    const nftFunctionsContract = getNftFunctionsContract(state);
    const balance = getEthBalance(state);
    const whiteListEntry = getWhitelistEntry(state) || { sig: '0x0', maxMints: 0 }; 
    const price = getPrice(state);

    await checkAddressAndRun(dispatch, address, web3, async () => {
      const value = amount * price;
      if (value > balance) {
        toast.error(<CustomErrorToast message={'Insufficient ETH amount'} />);
        return;
      }

      dispatch({ type: BUY_STARTED });
      
      await runContract(
        () => nftFunctionsContract.methods.mint(amount, whiteListEntry.sig, whiteListEntry.maxMints).send({ from: address, value }),
        { onHash: () => dispatch({ type: BUY_FINISHED }) }
      );

      dispatch(fetchAllInfo());
    });
  } catch (e) {
    dispatch({ type: BUY_FINISHED });
    console.log(e);
  }
};

const checkAddressAndRun = async (dispatch, address, web3, action) => {
  const [web3Account] = await web3.eth.getAccounts();
  if (!address || address !== web3Account) {
    return dispatch(connectWithMetaMask(action));
  }

  await action();
};

const runContract = async (action, options) => {
  return new Promise(async (resolve, reject) => {
    let txHash;
    let toastId;
    try {
      await action()
        .on('transactionHash', (hash) => {
          txHash = hash;
          toastId = toast(<TransactionInProgressToast hash={hash} />, { autoClose: false });
          if (options?.onHash) {
            options.onHash(hash);
          }
        })
        .on('receipt', (receipt) => {
          toast.dismiss(toastId);
          toast.success(<SuccessToast hash={txHash} />);
          resolve(receipt);
        });
    } catch (err) {
      toast.dismiss(toastId);
      if (err.code === TRANSACTION_CANCELLED) {
        toast.error(
          <CustomErrorToast message={'Transaction canceled by user'} />
        );
      } else {
        if (txHash) {
          toast.error(<TransactionErrorToast hash={txHash} />);
        } else {
          <CustomErrorToast message={'An error has occured completing this transaction'} />
        }
      }
      reject(err);
    }
  });
};
