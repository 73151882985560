import styles from "./Stakepage.module.scss";
import MainWrapper from "../components/UI/MainWrapper/MainWrapper";
import UnstakedPlants from "../components/Stakepage/UnstakedPlants";
import StakedPlants from "../components/Stakepage/StakedPlants";
import Footer from "../components/Footer/Footer";
import Details from "../components/Stakepage/Details/Details";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { fetchStakingInfo } from "../actions/staking";


const Stakepage = ({ fetchStakingInfo, tokens = [], stakedTokens = []}) => {

  useEffect(() => {
    fetchStakingInfo(0, true);
  }, [])
  
  console.log(stakedTokens);
  return (
    <div className={styles["dark-background"]}>
      
      <section className={styles["top-section"]}>
        <MainWrapper>
          <Details />
          {!!tokens.length &&
            <UnstakedPlants tokens={tokens} />
          }
        </MainWrapper>
      </section>
      
      {!!stakedTokens.length &&
        <MainWrapper>
          <StakedPlants tokens={stakedTokens}/>
        </MainWrapper>
      }
      
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.staking
});

const mapDispatchToProps = {
  fetchStakingInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stakepage);

